import routes from './routes'
import createQueryString from 'components/customHooks/useQueryString'

export const MenuData = [
  {
    label: 'Patient Directory',
    to: `${routes.index}${routes.patient.root}${routes.patient.directory}`,
  },
  {
    to: false,
    label: 'Action Items',
    icon: 'fas fa-headset',
    open: false,
    linkClass: 'active',
    sub: [
      {
        to: `https://testsmarterinc.freshdesk.com/`,
        label: 'Support Tickets',
        icon: 'fas fa-headset',
        bucket: 'SUPPORT_TICKETS',
        badgeParams: { testType: 'TICKETS', bucket: 'SUPPORT_TICKETS' },
        badgeClass: 'badge-danger',
      },
      {
        to: `${routes.index}${routes.fax.root}${routes.fax.incoming}?bucket=IN_FAX`,
        badgeParams: { testType: 'ALL', bucket: 'FAXES' },
        bucket: 'IN_FAX',
        label: 'Incoming Faxes',
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-warning',
      },
      {
        label: 'Failed Faxes',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?bucket=FAILED_FAXES&testType=POX,COX`,
        bucket: 'FAILED_FAXES',
        badgeParams: [
          { testType: 'POX_COX', bucket: 'FAILED_FAXES' },
          { testType: 'HST', bucket: 'FAILED_FAXES' },
        ],
        badgeClass: 'badge-warning',
      },
      {
        to: `${routes.index}${routes.order.root}?bucket=DM&testType=POX_COX_HST&actionItems=1`,
        badgeParams: routes.order.actionItems.directMessages,
        bucket: routes.order.actionItems.directMessages.bucket,
        label: 'Direct Messages',
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-warning',
      },
      {
        label: 'Awaiting Review',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.actionItems.awaitingReview,
        )}&actionItems=1`,
        bucket: routes.order.actionItems.awaitingReview.bucket,
        badgeParams: [routes.order.actionItems.awaitingReview, routes.order.hst.awaitingReview],
        badgeClass: 'badge-warning',
      },
      {
        label: 'Need Attention',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.actionItems.needAttention,
        )}&actionItems=1`,
        bucket: routes.order.actionItems.needAttention.bucket,
        badgeParams: [routes.order.actionItems.needAttention, routes.order.hst.needAttention],
        badgeClass: 'badge-warning',
      },
      {
        label: 'Demogr. Verification',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.actionItems.demographicVerification,
        )}&actionItems=1`,
        bucket: routes.order.actionItems.demographicVerification.bucket,
        badgeParams: [
          routes.order.actionItems.demographicVerification,
          routes.order.hst.demographicVerification,
        ],
        badgeClass: 'badge-warning',
      },
      {
        label: 'Insurance Verification',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.actionItems.insuranceVerification,
        )}&actionItems=1`,
        bucket: routes.order.actionItems.insuranceVerification.bucket,
        badgeParams: [
          routes.order.actionItems.insuranceVerification,
          routes.order.hst.insuranceVerification,
        ],
        badgeClass: 'badge-warning',
      },
    ],
  },
  {
    to: false,
    label: 'Pre-Billing',
    icon: 'fas fa-list',
    open: false,
    linkClass: 'active',
    sub: [
      {
        label: 'Awaiting Authorization',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.billing.authorization,
        )}`,
        badgeParams: routes.order.billing.authorization,
        bucket: routes.order.billing.authorization.bucket,
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-warning',
      },
      {
        label: 'Billing Review',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.billing.billingReview,
        )}`,
        badgeParams: routes.order.billing.billingReview,
        bucket: routes.order.billing.billingReview.bucket,
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-warning',
      },
      {
        label: 'Hold',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.billing.billingHold,
        )}`,
        badgeParams: routes.order.billing.billingHold,
        bucket: routes.order.billing.billingHold.bucket,
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-warning',
      },
      {
        label: 'Pending to CureMD',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.billing.billingQueued,
        )}`,
        badgeParams: routes.order.billing.billingQueued,
        bucket: routes.order.billing.billingQueued.bucket,
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-warning',
      },
      {
        label: 'Failed to CureMD',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.billing.billingFailed,
        )}`,
        badgeParams: routes.order.billing.billingFailed,
        bucket: routes.order.billing.billingFailed.bucket,
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-danger',
      },
    ],
  },
  {
    label: 'PulseOx-CapOx',
    open: false,
    to: false,
    linkClass: 'active',
    menu: 'pulseox-capox',
    sub: [
      {
        label: 'Active Orders',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.active,
        )}`,
        bucket: routes.order.pulseOxCapOx.active.bucket,
        badgeParams: routes.order.pulseOxCapOx.active,
        badgeClass: 'badge-info',
      },
      {
        label: 'Open Orders',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.open,
        )}`,
        bucket: routes.order.pulseOxCapOx.open.bucket,
        badgeParams: routes.order.pulseOxCapOx.open,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Demogr. Verification',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.demographicVerification,
        )}`,
        bucket: routes.order.pulseOxCapOx.demographicVerification.bucket,
        badgeParams: routes.order.pulseOxCapOx.demographicVerification,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Insurance Verification',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.insuranceVerification,
        )}`,
        bucket: routes.order.pulseOxCapOx.insuranceVerification.bucket,
        badgeParams: routes.order.pulseOxCapOx.insuranceVerification,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Need Attention',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.needAttention,
        )}`,
        bucket: routes.order.pulseOxCapOx.needAttention.bucket,
        badgeParams: routes.order.pulseOxCapOx.needAttention,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Awaiting Review',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.awaitingReview,
        )}`,
        bucket: routes.order.pulseOxCapOx.awaitingReview.bucket,
        badgeParams: routes.order.pulseOxCapOx.awaitingReview,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Logistics',
        labelClass: 'text-bold',
      },
      {
        label: 'New/Ready to Schedule',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.readyToSchedule,
        )}`,
        labelClass: 'ml-2',
        bucket: routes.order.pulseOxCapOx.readyToSchedule.bucket,
        badgeParams: routes.order.pulseOxCapOx.readyToSchedule,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Follow up',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.followUp,
        )}`,
        labelClass: 'ml-2',
        bucket: routes.order.pulseOxCapOx.followUp.bucket,
        badgeParams: routes.order.pulseOxCapOx.followUp,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Need to Ship',
        bucket: routes.order.pulseOxCapOx.needToShip.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.needToShip,
        )}`,
        labelClass: 'ml-2',
        badgeParams: routes.order.pulseOxCapOx.needToShip,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Ready to Ship',
        bucket: routes.order.pulseOxCapOx.readyToShip.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.readyToShip,
        )}`,
        labelClass: 'ml-2',
        badgeParams: routes.order.pulseOxCapOx.readyToShip,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Shipped to Patient',
        bucket: routes.order.pulseOxCapOx.shipped.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.shipped,
        )}`,
        labelClass: 'ml-2',
        badgeParams: routes.order.pulseOxCapOx.shipped,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Delivered',
        bucket: routes.order.pulseOxCapOx.delivered.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.delivered,
        )}`,
        labelClass: 'ml-2',
        badgeParams: routes.order.pulseOxCapOx.delivered,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Pending Return',
        bucket: routes.order.pulseOxCapOx.pendingReturn.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.pendingReturn,
        )}`,
        labelClass: 'ml-2',
        badgeParams: routes.order.pulseOxCapOx.pendingReturn,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Shipped to TestSmarter',
        bucket: routes.order.pulseOxCapOx.shippedBack.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.shippedBack,
        )}`,
        labelClass: 'ml-2',
        badgeParams: routes.order.pulseOxCapOx.shippedBack,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Completed',
        bucket: routes.order.pulseOxCapOx.completed.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.completed,
        )}`,
        badgeParams: routes.order.pulseOxCapOx.completed,
        badgeClass: 'badge-info',
      },
      {
        label: 'All Orders',
        bucket: routes.order.pulseOxCapOx.all.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.pulseOxCapOx.all,
        )}`,
        badgeParams: routes.order.pulseOxCapOx.all,
        badgeClass: 'badge-info',
      },
    ],
  },
  {
    to: false,
    label: 'Sleep Testing',
    icon: 'fas fa-hdd',
    open: false,
    linkClass: 'active',
    sub: [
      {
        label: 'Open Orders',
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.open)}`,
        bucket: routes.order.hst.open.bucket,
        badgeParams: routes.order.hst.open,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Processing',
        labelClass: 'text-bold',
      },
      {
        label: 'Verification',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.hst.orderVerification,
        )}`,
        labelClass: 'ml-2',
        bucket: routes.order.hst.orderVerification.bucket,
        badgeParams: routes.order.hst.orderVerification,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Patient Consent',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.hst.patientConsent,
        )}`,
        labelClass: 'ml-2',
        bucket: routes.order.hst.patientConsent.bucket,
        badgeParams: routes.order.hst.patientConsent,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Pre Authorization',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.hst.preAuthorization,
        )}`,
        labelClass: 'ml-2',
        bucket: routes.order.hst.preAuthorization.bucket,
        badgeParams: routes.order.hst.preAuthorization,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Patient Scheduling',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.hst.patientScheduling,
        )}`,
        labelClass: 'ml-2',
        bucket: routes.order.hst.patientScheduling.bucket,
        badgeParams: routes.order.hst.patientScheduling,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Device Shipping',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.hst.deviceShipping,
        )}`,
        labelClass: 'ml-2',
        bucket: routes.order.hst.deviceShipping.bucket,
        badgeParams: routes.order.hst.deviceShipping,
        badgeClass: 'badge-warning',
      },
      // {
      //   label: 'Device Delivery',
      //   labelClass: 'text-bold ml-2',
      // },
      // {
      //   label: 'New/Ready to Schedule',
      //   to: `${routes.index}${routes.order.root}?${createQueryString(
      //     routes.order.hst.readyToSchedule,
      //   )}`,
      //   labelClass: 'ml-4',
      //   bucket: routes.order.hst.readyToSchedule.bucket,
      //   badgeParams: routes.order.hst.readyToSchedule,
      //   badgeClass: 'badge-warning',
      // },
      // {
      //   label: 'Follow up',
      //   to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.followUp)}`,
      //   labelClass: 'ml-4',
      //   bucket: routes.order.hst.followUp.bucket,
      //   badgeParams: routes.order.hst.followUp,
      //   badgeClass: 'badge-warning',
      // },
      {
        label: 'Need to Ship',
        bucket: routes.order.hst.needToShip.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.needToShip)}`,
        labelClass: 'ml-4',
        badgeParams: routes.order.hst.needToShip,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Ready to Ship',
        bucket: routes.order.hst.readyToShip.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.hst.readyToShip,
        )}`,
        labelClass: 'ml-4',
        badgeParams: routes.order.hst.readyToShip,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Shipped to Patient',
        bucket: routes.order.hst.shipped.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.shipped)}`,
        labelClass: 'ml-4',
        badgeParams: routes.order.hst.shipped,
        badgeClass: 'badge-warning',
      },
      // {
      //   label: 'Delivered',
      //   bucket: routes.order.hst.delivered.bucket,
      //   to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.delivered)}`,
      //   labelClass: 'ml-4',
      //   badgeParams: routes.order.hst.delivered,
      //   badgeClass: 'badge-warning',
      // },
      {
        label: 'Patient Testing',
        to: `${routes.index}${routes.order.root}?${createQueryString(
          routes.order.hst.patientTesting,
        )}`,
        labelClass: 'ml-4',
        bucket: routes.order.hst.patientTesting.bucket,
        badgeParams: routes.order.hst.patientTesting,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Delivered',
        bucket: routes.order.hst.delivered.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.delivered)}`,
        labelClass: 'ml-4',
        badgeParams: routes.order.hst.delivered,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Pending Return',
        bucket: routes.order.hst.pendingReturn.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.pendingReturn)}`,
        labelClass: 'ml-4',
        badgeParams: routes.order.hst.pendingReturn,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Shipped to TestSmarter',
        bucket: routes.order.hst.shippedBack.bucket,
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.shippedBack)}`,
        labelClass: 'ml-4',
        badgeParams: routes.order.hst.shippedBack,
        badgeClass: 'badge-warning',
      },
      {
        label: 'Completed',
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.completed)}`,
        bucket: routes.order.hst.completed.bucket,
        badgeParams: routes.order.hst.completed,
        badgeClass: 'badge-info',
      },
      {
        label: 'All Orders',
        to: `${routes.index}${routes.order.root}?${createQueryString(routes.order.hst.all)}`,
        bucket: routes.order.hst.all.bucket,
        badgeParams: routes.order.hst.all,
        badgeClass: 'badge-info',
      },
    ],
  },
  {
    to: false,
    label: 'Faxes',
    icon: 'fas fa-store',
    open: false,
    linkClass: 'active',
    sub: [
      {
        to: `${routes.index}${routes.fax.root}${routes.fax.incoming}?bucket=IN_FAX`,
        badgeParams: { testType: 'ALL', bucket: 'FAXES' },
        bucket: 'IN_FAX',
        label: 'Incoming Faxes',
        icon: 'fas fa-list-alt',
        badgeClass: 'badge-warning',
      },
      {
        label: 'Pending',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?bucket=PENDING_FAXES&testType=POX,COX,HST`,
        bucket: 'PENDING_FAXES',
        badgeClass: 'badge-warning',
        badgeParams: [
          { testType: 'POX_COX', bucket: 'PENDING_FAXES' },
          { testType: 'HST', bucket: 'PENDING_FAXES' },
        ],
      },
      {
        label: 'Failed Faxes',
        icon: 'fas fa-list-alt',
        to: `${routes.index}${routes.order.root}?bucket=FAILED_FAXES&testType=POX,COX,HST`,
        bucket: 'FAILED_FAXES',
        badgeParams: [
          { testType: 'POX_COX', bucket: 'FAILED_FAXES' },
          { testType: 'HST', bucket: 'FAILED_FAXES' },
        ],
        badgeClass: 'badge-warning',
      },
    ],
  },
  {
    label: 'Device Management',
    icon: 'fas fa-store',
    open: false,
    to: `${routes.index}${routes.device.root}`,
    linkClass: 'active',
  },
  {
    to: false,
    label: 'Patient Tools',
    icon: 'fas fa-store',
    open: false,
    linkClass: 'active',
    sub: [
      // {
      //   label: 'Move Report',
      //   to: `${routes.index}${routes.patient.root}${routes.patient.moveReport}`,
      // },
      // {
      //   label: 'Edit Report Conditions',
      //   to: `${routes.index}${routes.patient.root}${routes.patient.editReportConditions}`,
      // },
      // {
      //   label: 'Merge Patient Accounts',
      //   to: `${routes.index}${routes.patient.root}${routes.patient.mergePatients}`,
      // },
      {
        label: 'Create New Order',
        to: `${routes.index}${routes.order.root}${routes.order.new}`,
      },
    ],
  },
  {
    to: false,
    label: 'Settings',
    icon: 'fas fa-store',
    open: false,
    linkClass: 'active',
    sub: [
      {
        label: 'Lab Insurance Payors',
        to: `${routes.index}${routes.payer.root}`,
      },
      {
        label: 'DME Insurance Payors',
        to: `${routes.index}${routes.dmePayer.root}`,
      },
      {
        label: 'DME Clients',
        icon: 'fas fa-building',
        to: `${routes.index}${routes.company.root}`,
      },
      {
        label: 'DME Users',
        icon: 'fas fa-people',
        to: `${routes.index}${routes.dmeUsers.root}${routes.dmeUsers.directory}`,
      },
      {
        label: 'Physician Groups',
        icon: 'fas fa-building',
        to: `${routes.index}${routes.physicianGroups.root}`,
      },
      {
        label: 'Physician Group Users',
        icon: 'fas fa-building',
        to: `${routes.index}${routes.physicianGroupUsers.root}${routes.physicianGroupUsers.directory}`,
      },
      // {
      //   label: 'Physicians',
      //   icon: 'fas fa-copyright',
      //   to: `${routes.index}${routes.brand.root}`,
      // },
      // {
      //   label: 'Comply Free Version',
      //   icon: 'fas fa-copyright',
      //   to: `${routes.index}${routes.brand.root}`,
      // },
      {
        label: 'Users Internal',
        icon: 'fas fa-copyright',
        to: `${routes.index}${routes.labUsers.root}${routes.labUsers.directory}`,
      },
    ],
  },
  {
    to: false,
    label: 'Management Reports',
    icon: 'fas fa-store',
    open: false,
    linkClass: 'active',
    sub: [
      {
        to: `${routes.index}${routes.reports.root}${routes.reports.stats}`,
        label: 'Stats',
        icon: 'fas fa-report',
      },
      {
        to: `${routes.index}${routes.reports.root}${routes.reports.analytics}`,
        label: 'Analytics',
      },
    ],
  },
  {
    to: `${routes.index}${routes.order.diagnostic}`,
    label: 'Order Diagnostics',
    icon: 'fas fa-headset',
    open: false,
    linkClass: 'active',
  },
]
