import { Routes, Route } from 'react-router-dom'

import Menu from './menu/Menu'
import Index from './index/Index'
import Profile from './profile/Profile'
import Nav from './nav/Nav'
import Footer from './footer/Footer'
import UserDirectory from './companyDirectory/employees/UserDirectory'
import UserUpsert from './companyDirectory/employees/UserUpsert'
import OfficesSearch from './companyDirectory/offices/OfficesSearch'
import OfficesDetailView from './companyDirectory/offices/OfficesDetailView'
import ManagerRole from './managerRole/ManagerRole'
import ManagerRoleUpsert from './managerRole/ManagerRoleUpsert'
import Device from './device/Device'
import DeviceUpsert from './device/DeviceUpsert'
import DeviceReset from './deviceReset/DeviceReset'
import DeviceCategory from './deviceCategory/DeviceCategory'
import DeviceCategoryUpsert from './deviceCategory/DeviceCategoryUpsert'
import Ticket from './ticket/Ticket'
import TicketUpsert from './ticket/TicketUpsert'
import HelpKnowledge from './helpKnowledge/HelpKnowledge'
import HelpDownload from './helpDownload/HelpDownload'
import HelpVideos from './helpVideos/index'
import PrintableForms from './printableForms'
import Order from './order/Order'
import AddOrder from './orderAdd/AddOrder'
import EditOrder from './orderEdit/EditOrder'
import TestCondition from './testConditions/TestCondition'
import PatientSearch from './patient/PatientSearch'
import PatientEdit from './patient/PatientEdit'
import RouteSearch from './route/RouteSearch'
import RouteUpsert from './route/RouteUpsert'
import RouteEdit from './route/RouteEdit'
import ErrorCard from '../atoms/ErrorCard'
import Analytics from './analytics'
import PhysicianSearch from './physician/PhysicianSearch'

import routes from './constants/routes'
import './css/adminlte.min.css'
import './css/extend.css'
import DeviceDirectory from './device/DeviceDirectory'
import DeviceDetail from './device/DeviceDetail'
import NewOrder from './order/NewOrder'
import NewPatient from './patient/NewPatient'
import NewPatientOrder from './order/NewPatientOrder'
import NewExistingPatientOrder from './order/NewExistingPatientOrder'

const DashboardDme = () => {
  return (
    <div className="wrapper">
      <Nav />
      <Menu />
      <div className="content-wrapper ts-content-wrapper">
        <Routes>
          <Route path="/" element={<Index />} />
          {/*<Route path="/" element={<PatientSearch />} /> */}
          <Route path={routes.profile.root} element={<Profile />} />
          <Route path={`${routes.profile.root}:id/`} element={<Profile />} />
          <Route path={`${routes.profile.root}:id/:tab`} element={<Profile />} />
          <Route path={routes.route.root} element={<RouteSearch />} />
          <Route path={`${routes.route.root}${routes.upsert}`} element={<RouteUpsert />} />
          <Route path={`${routes.route.root}${routes.upsert}:id`} element={<RouteUpsert />} />
          <Route
            path={`${routes.route.root}${routes.route.edit}:routeId`}
            element={<RouteEdit />}
          />
          <Route
            path={`${routes.route.root}${routes.route.edit}:routeId/:tab`}
            element={<RouteEdit />}
          />
          <Route path={`${routes.patient.root}`} element={<PatientSearch />} />
          <Route path={`${routes.patient.edit}:patientId/:tab`} element={<PatientEdit />} />
          <Route path={`${routes.patient.edit}:patientId`} element={<PatientEdit />} />
          <Route path={`${routes.user.root}`} element={<UserDirectory />} />
          <Route path={`${routes.user.root}/${routes.upsert}`} element={<UserUpsert />} />
          <Route path={`${routes.user.root}/${routes.upsert}:userId`} element={<UserUpsert />} />
          <Route path={`${routes.offices.root}`} element={<OfficesSearch />} />
          <Route path={`${routes.offices.officeDetail}:officeId`} element={<OfficesDetailView />} />
          <Route path={`${routes.manager.role}`} element={<ManagerRole />} />
          <Route path={`${routes.manager.role}/${routes.upsert}`} element={<ManagerRoleUpsert />} />
          <Route
            path={`${routes.manager.role}/${routes.upsert}:id`}
            element={<ManagerRoleUpsert />}
          />
          <Route path={routes.device.root} element={<Device />} />
          <Route path={`${routes.device.root}:tab`} element={<Device />} />
          <Route path={`${routes.device.root}${routes.upsert}`} element={<DeviceUpsert />} />
          <Route path={`${routes.device.root}${routes.upsert}:id`} element={<DeviceUpsert />} />
          <Route path={routes.device.deviceReset} element={<DeviceReset />} />
          <Route path={`${routes.device.deviceDirectory}`} element={<DeviceDirectory />} />
          <Route path={routes.device.deviceDetail} element={<DeviceDetail />} />
          <Route path={`${routes.device.deviceDetail}:deviceId`} element={<DeviceDetail />} />
          <Route path={routes.device.deviceCategory} element={<DeviceCategory />} />
          <Route
            path={`${routes.device.deviceCategory}${routes.upsert}`}
            element={<DeviceCategoryUpsert />}
          />
          <Route
            path={`${routes.device.deviceCategory}${routes.upsert}:id`}
            element={<DeviceCategoryUpsert />}
          />
          <Route path={routes.ticket.root} element={<Ticket />} />
          <Route path={`${routes.ticket.root}/${routes.upsert}`} element={<TicketUpsert />} />
          <Route path={`${routes.ticket.root}/${routes.upsert}:id`} element={<TicketUpsert />} />
          <Route path={routes.help.helpKnowledge} element={<HelpKnowledge />} />
          <Route path={routes.help.helpDownload} element={<HelpDownload />} />
          <Route path={routes.help.videos} element={<HelpVideos />} />
          <Route path={routes.printableForms.root} element={<PrintableForms />} />
          <Route path={routes.order.root} element={<Order />} />
          <Route path={`${routes.order.root}:deviceTypeId`} element={<Order />} />
          <Route path={`${routes.order.root}/hub`} element={<Order />} />
          <Route path={routes.order.add} element={<AddOrder />} />
          <Route path={`${routes.order.add}:patientId`} element={<AddOrder />} />
          <Route path={`${routes.order.edit}:orderId`} element={<EditOrder />} />
          <Route path={`${routes.order.edit}:orderId/:tab`} element={<EditOrder />} />
          <Route path={`${routes.order.edit}:orderId/:tab/:fileId`} element={<EditOrder />} />
          <Route path={`${routes.order.test}:orderId`} element={<TestCondition />} />
          <Route path={routes.physician.root} element={<PhysicianSearch />} />

          <Route
            path={`${routes.order.newOrder}${routes.patient.new}/orderDetails`}
            element={<NewPatientOrder />}
          />
          <Route
            path={`${routes.order.newOrder}patient/:patientId/orderDetails`}
            element={<NewExistingPatientOrder />}
          />
          <Route path={`${routes.order.newOrder}:patientId`} element={<NewOrder />} />
          {/* <Route path={`${routes.order.newOrder}`} element={<PatientSearch />} /> */}
          <Route path={`${routes.order.newOrder}`} element={<NewPatient />} />
          <Route path={`${routes.order.newOrder}${routes.patient.new}`} element={<NewPatient />} />
          <Route path={`${routes.analytics.root}`} element={<Analytics />} />
          <Route path="*" element={<ErrorCard />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default DashboardDme
