/** @jsxImportSource @emotion/react */
import { Field } from 'formik'
import { useState } from 'react'
import BsButton from 'react-bootstrap/Button'
import { confirm } from '../../../molecules/Confirmation'

interface IProps {
  testType: string
  values: any
  onShipping: () => void
  onManualShipping: () => void
  onLocalPickup: () => void
}

export const ShippingMethod = ({
  onShipping,
  testType,
  values,
  onManualShipping,
  onLocalPickup,
}: IProps) => {
  const [showManualShipping, setShowManualShipping] = useState(false)
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  return (
    <>
      {!showManualShipping && (
        <>
          <p>Select action: </p>
          <BsButton
            disabled={submitted}
            className={'mb-2'}
            onClick={() => {
              setSubmitted(true)
              onShipping()
            }}
            css={{
              width: '100%',
            }}>
            Start Shipping
          </BsButton>
        </>
      )}
      {testType === 'HST' && !showManualShipping && (
        <>
          <BsButton
            className={'mb-2'}
            variant={'outline-primary'}
            onClick={() => {
              setShowManualShipping(true)
            }}
            css={{
              width: '100%',
            }}>
            Manual Shipping
          </BsButton>
          <BsButton
            className={'mb-2'}
            variant={'outline-primary'}
            onClick={() => {
              confirm('', {
                title: 'Are you sure?',
                description: 'This means that the patient will pick up the device.',
                yesAction: 'Yes',
                noAction: 'Cancel',
              })
                .then(onLocalPickup)
                .catch(() => {})
            }}
            css={{
              width: '100%',
            }}>
            Local Pickup
          </BsButton>
        </>
      )}
      {showManualShipping && (
        <div
          css={{
            marginBottom: 10,
          }}>
          <BsButton
            className={'mb-1'}
            onClick={() => {
              setShowManualShipping(false)
            }}>
            {'< Back'}
          </BsButton>
          <div>
            <label
              htmlFor="trackingNumber"
              className={'font-weight-normal mt-2'}
              css={{ color: '#888888' }}>
              Tracking Number
            </label>
            <Field
              type="text"
              name="trackingNumber"
              className="form-control"
              placeholder="Enter Tracking Number"
              value={values.trackingNumber}
              autoComplete="off"
            />
          </div>
          <div>
            <label
              htmlFor="returnTrackingNumber"
              className={'font-weight-normal mt-2'}
              css={{ color: '#888888' }}>
              Return Tracking Number
            </label>
            <Field
              type="text"
              name="returnTrackingNumber"
              className="form-control"
              placeholder="Enter Return Tracking Number"
              value={values.returnTrackingNumber}
              autoComplete="off"
            />
          </div>
          {error && (
            <p
              className={'text-danger'}
              css={{
                margin: 0,
                padding: 0,
                marginTop: 5,
              }}>
              {error}
            </p>
          )}
          <BsButton
            className={'mt-3'}
            onClick={() => {
              if (!values.returnTrackingNumber || !values.trackingNumber) {
                setError('Enter both tracking numbers.')
                return
              }

              onManualShipping()
            }}
            css={{
              width: '100%',
            }}>
            Start Manual Shipping
          </BsButton>
        </div>
      )}
    </>
  )
}
